/* eslint-disable react/no-danger */
import React from "react"
import { graphql, Link } from "gatsby"
import { RichText, Element, Image } from "prismic-reactjs"
import { Helmet } from "react-helmet"
import { FluidObject } from "gatsby-image"
import { Layout } from "../components/layout"
import { linkResolver } from "../utils/linkResolver"
import { formatDate } from "../data/utils"
import { PrismicImage } from "../components/PrismicImage"

interface Props {
  data: {
    prismic: {
      allWelcomes: {
        edges: {
          node: {
            _meta: {
              tags: string[]
              lastPublicationDate: string
            }
            title: Element[]
            content: Element[]
            portrait?: Image
            portraitSharp?: {
              childImageSharp: {
                fluid: FluidObject
              }
            }
            fullname: Element[]
            position: Element[]
          }
        }[]
      }
    }
  }
}

const WelcomePrismicTemplate: React.FC<Props> = ({ data }) => {
  const { edges } = data.prismic.allWelcomes
  if (edges.length === 0) {
    return (
      <Layout>
        <div className="container h1-compact mt-3 mb-3">
          <h1>Article introuvable</h1>
          <Link to="/" className="btn btn-primary">
            Retour à l'accueil
          </Link>
        </div>
      </Layout>
    )
  }

  const post = edges[0].node

  const meta = [
    {
      name: "description",
      content: "Mot de bienvenue de notre président.",
    },
  ]

  if (post._meta.tags.length > 0) {
    meta.push({ name: "keywords", content: post._meta.tags.join() })
  }

  return (
    <Layout>
      <div className="container h1-compact mt-3 mb-3">
        <Helmet title={RichText.asText(post.title)} meta={meta} />

        <div className="post-wrapper">
          <RichText render={post.title} linkResolver={linkResolver} />

          <em>
            <small>Publié le {formatDate(new Date(post._meta.lastPublicationDate))}</small>
          </em>

          <div className="post-content">
            <RichText render={post.content} linkResolver={linkResolver} />
          </div>

          <hr />

          <div className="signature">
            <div className="row align-items-center">
              <div className="col image-container">
                <PrismicImage image={post.portrait} imageSharp={post.portraitSharp} withCaption={false} />
              </div>
              <div className="col">
                <RichText render={post.fullname} linkResolver={linkResolver} />
                <RichText render={post.position} linkResolver={linkResolver} />
              </div>
            </div>
          </div>

          <hr />

          <div className="text-center mt-4">
            <Link to="/" className="btn btn-outline-secondary">
              <span className="icon-chevron-right" />
              Retour à l&#39;accueil
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query WelcomeQuery {
    prismic {
      allWelcomes(first: 1) {
        edges {
          node {
            _meta {
              tags
              lastPublicationDate
            }
            title
            content
            portrait
            portraitSharp {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            fullname
            position
          }
        }
      }
    }
  }
`

export default WelcomePrismicTemplate
